import * as React from 'react'
import { graphql } from 'gatsby'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { StaticImage } from "gatsby-plugin-image";

export const TopMenu = () => {
  return (
    <>
      {['md'].map((expand) => (
        <Navbar key={expand} bg="" expand={expand} className="bg-white">
          <Container>
            <Navbar.Brand href="/">
              <div style={{ width: "200px" }}>
                <StaticImage src="../images/logo20.png" alt="Interno4 e NdApress" />
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>

                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1">
                  <Nav.Link href="/catalog/interno4">Interno4</Nav.Link>
                  <Nav.Link href="/catalog/nda">NdA</Nav.Link>
                  <Nav.Link href="/page/contacts">Contatti</Nav.Link>
                  <Nav.Link href="/page/privacy-policy">Privacy Policy</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export const query = graphql`
  fragment TopMenuFragment on PrismicTopMenu {
    _previewable
    type
    lang
    data {
      menu_links {
        label {
          richText
          html
          text
        }
        link {
          id
          url
        }
      }
    }
  }
`
